
.footer{
  font-family: 'Fight to the Finish BB';
  font-weight:bold;
  background-color: var(--primary-4);
  display: flex;
  width: 100vw;
  height: 3vh;
  position: fixed;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
  box-shadow: 0 -3px 5px 3px rgba(0, 0, 0, 0.6), 0 -3px 20px 3px rgba(0, 0, 0, 0.12);
  z-index: 997;
}
.copy{
  margin-left: 1vw;
  font-size: 1.25vw;
}
.site-links{
  width: 20vw;
  font-size: small;
  display: flex;
  justify-content: space-between;
  margin-right: 1vw;
  padding: 3px;
}
.footer-link:hover{
  cursor: pointer;
  background-color: var(--menu-background-color);
  transition: 0.25s;
  border: solid 1px var(--menu-background-color);
  border-radius: 5px;
}
.footer-link{
  text-decoration: none;
  color: var(--text-color);
  font-size: 1.25vw;
  padding: 3px;
}
@media screen and (min-width: 1024px){
 .footer-link{
    font-size: 0.8vw;
  }
  .copy{
    font-size: 0.8vw;
  }
}

@media screen and (max-width: 766px){
  .footer{
    font-size: 1.75vw;
  }
  .footer-link{
    text-decoration: none;
    color: var(--text-color);
    font-size: 1.75vw;
  }
}

