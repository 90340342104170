

@font-face {
  font-family: 'Fight to the Finish BB';
  src: url('../fonts/FightToTheFinishBBBold/font.woff2');
}

@font-face {
  font-family: 'Fight to the Finish BBBoldItalic';
  src: url('../fonts/FightToTheFinishBBBoldItalic/font.woff2');
}
:root{
  --primary-1: rgba(13, 48, 129, 1);
  --primary-2: rgba(66, 97, 168, 1);
  --primary-3: rgba(28, 69, 163, 1);
  --primary-4: rgba(8, 37, 103, 1);
  --primary-5: rgba(3, 24, 71, 1);
  --text-color: rgba(188,222,236,1);
  --outline-color: rgb(188,222,236,1);
  --menu-background-color: rgba(255, 152, 0, 0.6);
  --soon-color: rgb(255, 152, 0, 1);
  --lightning-color: rgb(255, 200, 0,.6);
  margin: 0;
}
body {
  margin: 0;
  font-family: 'Fight to the Finish BB';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-3);
}

