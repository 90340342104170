
body{
  overflow: hidden;
}
.topnav{
    height: 5vh;
    padding: 0.5%;
    width: 100vw;
    font-family: 'Fight to the Finish BBBoldItalic';
    background-color:var(--primary-4);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.6), 0 5px 20px 5px rgba(0, 0, 0, 0.12);
    position: fixed;
    top: 0;
    z-index: 999;
  }
  .siteName{
    display: flex;
    height: 100%;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    margin-left: 1vw;
  }
  #siteHome{
    padding: 5px;
    font-size: 2.1vw;
    font-weight: bold;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.80);
    padding: 5px 10px;
    text-align: start;
  }
  #logo{
    height: 4vh;
    width: 3vw;
    margin-left: 0.25vw;
    margin-right: 0.25vw;
    padding: 5px;
  }
  #logo:hover{
    cursor:pointer;
    background-color: var(--lightning-color);
    border: solid 1px var(--lightning-color);
    border-radius: 20px;
    transition: 0.25s;
  }
  .watch {
    position: absolute;
    top: 5vh;
    height: 95vh;
    width: 100vw;
  }
  .soon{
    display: flex;
    padding: 5px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    text-align: center;
    justify-content: center;
    align-items: center;
    top: 30vh;
    width: 100vw;
    height: 10vh;
    font-family: 'Fight to the Finish BB';
    font-size: 5vw;
    color: var(--soon-color);
    z-index: 99;
  }
  .soon1{
    display: flex;
    padding: 5px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    text-align: center;
    justify-content: center;
    align-items: center;
    top: 40vh;
    width: 100vw;
    height: 10vh;
    font-family: 'Fight to the Finish BB';
    font-size: 5vw;
    color: var(--soon-color);
    z-index: 99;
  }
  .menu{
    margin-right: 2vw;
    font-size: 0.78vw;
    height: 100%;
    display: none;
    color: var(--text-color);
  }
  .menu-link{
    color: var(--text-color);
    text-decoration: none;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.80);
    align-items: center;
  }
  .menu-link:hover{
    text-shadow: 5px 5px 5px rgba(255, 255, 255, 0.7);
    transition: 0.25s;
  }
  #siteHome:hover{
    background-color: var(--menu-background-color);
    text-shadow: 5px 5px 5px rgba(255, 255, 255, 0.7);
    transition: 0.25s;
    border: solid 1px var(--menu-background-color);
    border-radius: 8px;
  }
  .menu a{
    height: 60%;
    display: inline-flex;
    align-items: center;
    padding: 10px;
    font-weight: bold;
    font-size: 1.75vw;
    transition: 0.3s;
  }
  .menu a:hover{
    background-color: var(--menu-background-color);
    color: #fff;
    border: solid 1px var(--menu-background-color);
    border-radius: 8px;
    transition: 0.25s;
  }
  .show-mobile-menu-button{
    display: inline-flex;
    margin-right: 1.5rem;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;
    font-size: 2.5em;
    color: #fff;
    cursor: pointer;
    z-index: 999;
  }
  .close-mobile-menu-button{
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;
    font-size: 50px;
    color: #fff;
    cursor: pointer;
  }
  .mobile-menu{
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.65);
    transition: 0.3s;
  }
  .mobile-menu a{
    padding: 10px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: bold;
    font-size: 2rem;
    text-decoration: none;
  }
  .mobile-menu a:hover{
    border: solid 1px var(--menu-background-color);
    border-radius: 8px;
    background-color: var(--menu-background-color);
    color: white;
  }
  @media screen and (min-width: 1440px){
    .topnav{
      justify-content: space-between;
  }
  .show-mobile-menu-button{
      display: none;
  }
  .menu{
      display: inline-flex;
      align-items: center;
  }
  .menu a{
    font-size: 1.1vw;
  }
  .menu:hover{
      color: white;
  }
  #siteHome{
    font-size: 2.1vw;
  }
  #logo{
    height: 4vh;
    width: 3vw;
    margin-left: 0.25vw;
    margin-right: 0.25vw;
    padding: 5px;
  }
  }
  @media screen and (min-width: 1256px) and (max-width: 1439px){
    .topnav{
      justify-content: space-between;
  }
  .show-mobile-menu-button{
      display: none;
  }
  .menu{
      display: inline-flex;
      align-items: center;
  }
  .menu a{
    font-size: 1.2vw;
  }
  .menu:hover{
      color: white;
  }
  #siteHome{
    font-size: 2.1vw;
  }
  #logo{
    height: 4vh;
    width: 3vw;
    margin-left: 0.25vw;
    margin-right: 0.25vw;
    padding: 5px;
  }
  }
  @media screen and (min-width: 1024px) and (max-width: 1255px) {
    .topnav{
        justify-content: space-between;
    }
    .show-mobile-menu-button{
        display: none;
    }
    .menu{
        display: inline-flex;
        align-items: center;
    }
    .menu a{
      font-size: 1.3vw;
    }
    .menu:hover{
        color: white;
    }
    #siteHome{
      font-size: 2.1vw;
    }
    #logo{
      height: 4vh;
      width: 3vw;
      margin-left: 0.25vw;
      margin-right: 0.25vw;
      padding: 5px;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1023px){

 .topnav{
      justify-content: space-between;
  }
  .show-mobile-menu-button{
      display: none;
  }
  .menu{
      display: inline-flex;
      align-items: center;
  }
  .menu a{
    font-size: 1.5vw;
  }
  .menu:hover{
      color: white;
  }
  #siteHome{
    font-size: 2.1vw;
  }
  #logo{
    height: 4vh;
    width: 3vw;
    margin-left: 0.25vw;
    margin-right: 0.25vw;
    padding: 5px;
  }
  }
@media screen and (max-width: 766px){
  #siteHome{
    font-size: 3.75vw;
  }
  #logo{
    height: 4vh;
    width: 4.5vw;
    margin-left: 0.25vw;
    margin-right: 0.25vw;
    padding: 5px;
  }

}